* {
  -webkit-user-select: none;
  user-select: none;
}

html {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

#root {
  width: 100%;
  height: 100%;
}

body {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #282c34;
  color: white;
  padding: 0;
  margin: 0;
}

@media only screen and (min-width: 1024px) and (orientation: landscape) {
  #root {
      width: calc(100vh * (9 / 16)); /* 9:16 aspect ratio based on viewport height */
      min-width: 1024px;
      height: 100vh;
  }
}


