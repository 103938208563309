.settings-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5vw;
  height: 100%;
  justify-content: space-between;
  box-sizing: border-box;
}
.language-bar {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

input[type="checkbox"] {
  zoom: 4;
}

.settings-title {
  font-size: 10vw;
}

label {
  display: flex;
  align-items: center;

  font-size: 5vw;
}

.close-button {
  min-width: 25vw;
  height: 10vh;
  border: none;
  border-radius: 1vw;
  background-color: rgba(255, 255, 255, 0.8);
  font-weight: bold;
  font-size: 5vh;
}
.reload-button {
  /* width: 15vw; */
  height: 10vh;
  border: none;
  border-radius: 1vw;
  background-color: rgba(255, 177, 177, 0.8);
  font-weight: bold;
  font-size: 3vh;
}

.button-panel {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
}