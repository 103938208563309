.grid-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.grid-list > * {
  flex-grow: 0;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  border: 0.8vw solid white;
}

.grid-list.columns-2 > * {
  width: 40vw;
  height: 40vw;
  border-radius: 3vw;
  margin: 3vw;
}

.grid-list.columns-3 > * {
  width: 25vw;
  height: 25vw;
  border-radius: 2vw;
  margin: 2vw;
}
