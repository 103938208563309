.color-pane {
  width: 100%;
  height: 100%;
  position: relative;
  transition: background-color 0.5s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.speech-text-block {
  position: absolute;
  top: 0;
  width: 100%;
  text-align: center;
  font-size: 3em;
  background: none;
}

.image-block {
  width: 50%;
  /* height: 50%; */
  background-repeat: no-repeat;
  background-size: contain;
  filter: drop-shadow(0em 1em 3em #222);
  transition: opacity 0.5s;
  opacity: 1;
}

.image-block.hidden {
  opacity: 0;
}
