/* @media screen and (max-aspect-ratio:4/3) {
  .letter-container {
  }
}

@media screen and (min-aspect-ratio:4/3) {
  .letter-container {
  }
} */

.letter-container {
  position: relative;
  width: 12vmin;
  height: 24vmin;
  font-size: 7.5vmin;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-around;
  /* Move to options */
  font-family: sans-serif;
  /* font-family: cursive; */
}

.letter {
  position: absolute;
  width: 100%;
  height: 50%;
  top: 50%;
  transition: transform 1s;
  align-items: center;
  justify-content: center;
  display: flex;

  font-weight: bold;
  cursor: pointer;
  user-select: none;
  pointer-events: none;
  overflow: hidden;
  z-index: 10;
  text-shadow: 0 0 2vmin rgb(0, 0, 0);
}

.letter-box {
  border: 0.5vmin solid white;
  border-radius: 1.5vmin;
  height: 50%;
  user-select: none;
  cursor: pointer;
  box-sizing: border-box;
  margin: 0.5vmin;
  transition: 300ms;
}

.letter-box-blank {
  cursor: default;
}

.letter-box:active {
  background-color: red;
  transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
}

.level.completed .letter-box-top {
  background-color: green;
}

.level.completed .cells-row {
  transition: transform 500ms;
  transform: translate(0, 30%);
}

.level.completed .letter-box-bottom {
  transition: all 500ms;
  transform: translate(0, 100%);
  opacity: 0;
}

