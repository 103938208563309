.games-menu {
  width: 100%;
  height: 100%;
}

.game {
  width: 100%;
  height: 100%;
}

a > .card-block {
  color: white;
}