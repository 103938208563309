.game-imguess {
  overflow-y: auto;
}

.level-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 3vw;
}
.level-card-wrapper {
  width: calc(50% - 3vw);
  aspect-ratio: 1 / 1;
  flex-grow: 0;
}

.level-card {
  width: 100%;
  height: 100%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  border: 0.8vw solid white;
  border-radius: 3vw;
  box-sizing: border-box;
}