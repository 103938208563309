.card-block {
  height: 100%;
  width: 100%;
  background-size: contain;
  position: relative;
}

.card-caption-text {
  width: 100%;
  text-align: center;
  font-size: 7vw;
  font-family: cursive;
  text-shadow: 0 0 1px #fff, 0 0 2px #fff, 0 0 6px #292736, 0 0 8px #292736, 0 0 10px #292736, 0 0 12px #292736, 0 0 14px #292736;
}

.card-block > .card-caption {
  position: absolute;
  align-items: flex-end;
  width: 100%;
  height: 100%;
  display: flex;
}