.sudoku-root {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 90%;
  padding-left: 5%;
  padding-right: 5%;
  justify-content: stretch;
  gap: 5%;
  & button {
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    font: inherit;
    color: inherit;
    text-align: inherit;
    cursor: pointer;
    outline: none;


    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    aspect-ratio: 1;
    border: 0.5px solid #fff2;
    &:hover {
      background-color: #fff1;
    }
    &.selected {
      background-color: #0ff2;
    }
    &:disabled {
      color: #fff4;
    }
    &.known {
      background-color: #ffffff08;
    }

    &.incorrect {
      background-color: #f004;
    }
  }
}

.sudoku-header {
  height: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.game-board {
  display: flex;
  flex-direction: column;
  aspect-ratio: 1;
  font-size: calc(min(5vw, 5vh));
  border: 0.5px solid #fff2;
}

.game-board .row {
  display: flex !important;
  flex-direction: row;
  flex: 1;
  &:first-child {
    border-top: 2px solid #fff3;
  }
  &:nth-child(3n) {
    border-bottom: 2px solid #fff3;
  }
  & > .cell {
    &:first-child {
      border-left: 2px solid #fff3;
    }
    &:nth-child(3n) {
      border-right: 2px solid #fff3;
    }
  }
}

.state-won {
  color: #0f0;
  font-weight: bold;
}


.game-controls .number-list {
  display: flex;
}