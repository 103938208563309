.settings-icon {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 6vw;
  opacity: 0.5;
}