@media screen and (max-aspect-ratio:4/3) {
  /* portrait */
  .image-container {
    width: 90%;
    height: 90%;
  }
}

@media screen and (min-aspect-ratio:4/3) {
  /* landscape */
  .image-container {
    height: 100%;
    width: 100%;
  }
}

.level {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: stretch;
  opacity: 0;
  transition: opacity 500ms;
  overflow: hidden;
}

.level.started {
  opacity: 1;
}

.cells-row {
  margin: 1vmin;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-self: center;
  max-width: 100%;
}

.image-row {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  overflow: hidden;
}

.level.completed .image-row {
  transition: 1s;
  transform: translateY(4vmin) scale(1.1);
}

.level.completed .cells-row {
  cursor: pointer;
}

.level.completed .cells-row .letter-box-top {
  cursor: pointer;
}


.image-container {
  flex-grow: 0;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  transition: opacity 0.5s;
}

.level.completed .image-container {
  animation-name: endlevel1;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-duration: 1s;
}

.level.started:not(.completed) .image-container {
  animation-name: idle1;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 5s;
}

@keyframes endlevel1 {
  0% { transform: rotate(0deg) scale(1);  }
  25%  { transform: rotate(-5deg) scale(1.01);  }
  50%  { transform: rotate(0deg) scale(0.99);  }
  75%  { transform: rotate(5deg) scale(1.02);  }
  100%  { transform: rotate(0deg) scale(1); }
}

@keyframes idle1 {
  0% { transform: scale(1); }
  50%  { transform: scale(0.98); }
  100%  { transform: scale(1); }
}
